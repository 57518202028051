<template>
  <v-container>
    <v-card v-if="targetAudienceEntity &&view" flat color="rgb(247,249,250)">
        <v-card-text class="mt-n7">
        <v-row>
          <v-col cols="12">
            <div class="text-h6 font-weight-bold mx-1 text--primary">
              {{targetAudienceEntity.targetAudienceName}}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card color="rgb(247,249,250)" width="280px" outlined rounded @click="selectSkillCard" v-else>
      <v-card-text class="mt-n7">
        <v-row>
          <v-col cols="12">
            <div class="text-h6 font-weight-bold mx-1">
              {{targetAudienceEntity.targetAudienceName}}
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-1" v-if="targetAudienceEntity.targetAudienceDesigantions.length > 0">
          <div class="text-h6 font-weight-bold text--black">
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container> 
</template>
<script>
import { mapState } from "vuex";
export default {
    name:"SelectTargetCard",
    props:["targetAudienceEntity","type" ,"view"],
    computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerSkillServicesDto: "trainerSkillServicesDto"
    })
  },
    methods:{
      selectSkillCard(){
        this.$store.dispatch('trainerProfile/createTrainerTargetAudince',{targetAudience:this.targetAudienceEntity,type:this.type})
      }
    }
}
</script>

<style>

</style>