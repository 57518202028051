<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="3" class="ml-n5">
        <SKillCardView :skillEntitiy="skillEntitiy" view="true" />
      </v-col>
      <v-col cols="2">
        <v-row>
          <div class="text-h7 font-weight-bold primary--text my-1 mx-3">
            Trainer verified
          </div>
        </v-row>
        <v-row>
          <v-checkbox v-if="isVerifySkill"
            v-model="skillEntitiy.trainerVerifiedSkill"
            label=""
            :disabled="skillEntitiy.dueDeligenceVerifiedSkill"
            class="ml-8"
          ></v-checkbox>
          <span v-else class="text-h8 font-weight-bold text--primary my-1 mx-10">{{skillEntitiy.trainerVerifiedSkill?"YES":"NO"}}</span>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row>
          <div class="text-h7 font-weight-bold primary--text my-1">
          Skill  Validated 
          </div>
        </v-row>
        <v-row>
          <v-checkbox
          v-if="isVerifySkill"
            v-model="skillEntitiy.dueDeligenceVerifiedSkill"
            label=""
            
            :disabled="!skillEntitiy.trainerVerifiedSkill"
            class="ml-12"
          ></v-checkbox>
           <span v-else class="text-h8 font-weight-bold text--primary my-1 mx-10">{{skillEntitiy.dueDeligenceVerifiedSkill?"YES":"NO"}}</span>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="isVerifySkill" cols="3">
        <v-row justify="start">
          <div class="text-h7 font-weight-bold primary--text my-1 mx-10">
            Move skills
          </div>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-select outlined placeholder="Move to" :items="itemsSkillMove" item-text="name"  item-value="value" v-model="skillEntitiy.skillType">
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SKillCardView from "./skillCardView.vue";
export default {
  name: "VerificationSkillCard",
  components: {
    SKillCardView
  },
  props: ["skillEntitiy", "isVerifySkill"],
  data() {
    return {
      itemsSkillMove: [
        {
          name: "Primary Skills",
          value: "PRIMARY_SKILLS"
        },
        {
          name: "Secondary Skills",
          value: "SECONDARY_SKILLS"
        },
        {
          name: "Aspirational Skills",
          value: "ASPIRATIONAL_SKILLS"
        }
      ]
    };
  }
};
</script>

<style></style>
