<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row justify="space-around">
      <v-col col="12">
        <div class="text-h6 font-weight-bold mx-1">
          Skills and service expereince
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ProfileCard/>
      </v-col>
    </v-row>
    <v-row>
    <div class="text-h6 font-weight-bold mx-2 text--primary">
              Stage 1C 
    </div>
    </v-row>
    <v-row><v-col cols="5"><Span class="text-h6 font-weight-bold primary--text">Rough skills and primary skills <ToolTip :tooltipText="skillAndServicesHelpText.primarySkills"/></span> </v-col>
    <v-col cols="1" class="mx-6">
    <PrimarySkill v-if="trainerSkillServicesDto.primarySkills.length==0" type="PRIMARY_SKILLS"/>
    </v-col>
    </v-row>
    <v-row v-if="trainerSkillServicesDto.primarySkills.length>0">
      <v-col cols="12" class="ml-n4">
      <PrimarySkillCard type="PRIMARY_SKILLS"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="ml-n4">
      <ToolsPlatfromCard typeOf="TOOLSANDPLATFORM"/>
      </v-col>
      <v-col cols="6">
        <TargetAudinceCard  type="PRIMARY_SKILLS_TARGET_AUDIENCE" :edit="trainerSkillServicesDto.primarySkillsTargetAudience!=null?true:false"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="ml-n4">
      <ToolsPlatfromCard typeOf="TARGETINDUSTRY"/>
      </v-col>
      <v-col cols="6">
      <ToolsPlatfromCard typeOf="TARGETDEPARTMENT"/>
      </v-col>
    </v-row>
    <v-row v-if="trainerSkillServicesDto.secondarySkills.length==0"><v-col cols="5"><Span class="text-h6 font-weight-bold mx-1 primary--text" >Secondary Skills <ToolTip :tooltipText="skillAndServicesHelpText.secondarySkills"/></span> </v-col>
     <v-col cols="1" class="mx-6">
       <PrimarySkill type="SECONDARY_SKILLS" v-if="trainerSkillServicesDto.secondarySkills.length==0" />
     </v-col>
    </v-row>
    <v-row v-if="trainerSkillServicesDto.secondarySkills.length>0">
      <v-col cols="12">
      <PrimarySkillCard type="SECONDARY_SKILLS"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="ml-n4">
        <TargetAudinceCard  type="SECONDARY_SKILLS_TARGET_AUDIENCE" :edit="trainerSkillServicesDto.secondarySkillsTargetAudience!=null?true:false"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <div class="text-h6 font-weight-bold mx-1 primary--text">
          Service delivery expereince
        </div>
      </v-col>
      <v-sapcer></v-sapcer>
      <v-col cols="1">
        <SkillAndServicesDailog v-if="trainerSkillServicesDto.trainerServiceDelivery==null" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ServiceDeliveryExperinceCard/>
      </v-col>
    </v-row>
      <a class="text-h6 font-weight-bold  blue--text"  @click="routeTo">
            Click here to fill trainer fee details 
      </a> 
    <v-row><v-col cols="5"><Span class="text-h6 font-weight-bold mx-1 primary--text">Aspirational Skills</span></v-col>
    <v-col cols="1" class="mx-6">
      <PrimarySkill type="ASPIRATIONAL_SKILLS"  v-if="trainerSkillServicesDto.aspirationalSkills.length==0"/>
    </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="trainerSkillServicesDto.aspirationalSkills.length>0">
      <PrimarySkillCard type="ASPIRATIONAL_SKILLS"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="ml-n4">
        <TargetAudinceCard  type="ASPIRATIONAL_SKILLS_TARGET_AUDIENCE" :edit="trainerSkillServicesDto.aspirationalSkillsTargetAudience!=null?true:false"/>
      </v-col>
    </v-row>
    <div :class="true?activeClass:nonActive">
             Admin comments
    </div>
    <AdminComments :showAddButton="true" type="SKILLSANDSERVICES"/>
  </v-container>
</template>

<script>
import PrimarySkill from "../../../components/primarySkilldailog.vue"
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import ProfileCard from "../../../components/ProfileCard/ProfileCard.vue";
import PrimarySkillCard from "../../../components/SkillsCardPrimary.vue";
import ToolsPlatfromCard from "../../../components/toolsPlatfromCard.vue"
import TargetAudinceCard from '../../../components/targetAudinceCard.vue'
import AdminComments from "../../../components/AdminComments/AdminComments.vue";
import SkillAndServicesDailog from "../../../components/SkillAndServicesDailog/skillAndServices.vue"
import ServiceDeliveryExperinceCard from '../../../components/ServiceDeliveryExperinceCard.vue'
import { mapState } from "vuex";
import ToolTip from '../../../components/Tooltip/tooltip.vue'
import {SkillAndServices} from '../../../data/HelpTexts'
export default {
  name: "ServicesAndSkills",
  components: {
    SecondaryNavigation,
    ProfileCard,
    TargetAudinceCard,
    AdminComments,
    SkillAndServicesDailog,
    PrimarySkill,
    PrimarySkillCard,
    ToolsPlatfromCard,
    ServiceDeliveryExperinceCard,
    ToolTip
  },
  data() {
    return {
      skillAndServicesHelpText:SkillAndServices,
       activeClass:"text-h6 font-weight-bold mx-3",
      nonActive:"text-h6 primary--text font-weight-bold", 
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      trainerSkillServicesDto:'trainerSkillServicesDto'
    })
  },
  
  created(){
    this.$store.dispatch('trainerProfile/getSkillServicesDto',this.profile.trainerId)
  },
  methods: {
    routeTo(){
      this.$router.push('/pages/paymentDetails');
    },
    saveDomainAndPrimarySkills(){
      this.$store.dispatch('trainerProfile/createTrainerSkillServicesDTO' , this.trainerSkillServicesDto)
    },
    updateSkillsDomianAndPrimarySkills(){
      this.$store.dispatch('trainerProfile/updateSkillServicesDto',this.trainerSkillServicesDto)
    }
  },
};
</script>

<style></style>
