<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px" width="600px">
        <template v-slot:activator="{ on }">
          <v-icon color="primary"  v-on="on" v-if="(index||index==0)" @click="getToolsToEdit">
            edit
          </v-icon>
          <v-icon color="primary" v-on="on" v-else>
            add
          </v-icon>
           
        </template>
        <v-card outlined>
          <v-form v-model="valid" ref="createEditSkillForm">
            <v-card-title>
              <v-row>
                <v-col cols="10" v-if="type==types.toolsAndPlatfrom">
                  <span class="text-h5 font-weight-bold mx-n1" v-if="index">
                    Edit tools, platfrom , methodology
                  </span>
                  <span class="text-h5 font-weight-bold mx-n1" v-else>
                    Add tools, platfrom , methodology
                  </span>
                </v-col>
                <v-col cols="10" v-else-if="type==types.targetIndustry">
                  <span class="text-h5 font-weight-bold mx-n1" v-if="index">
                    Edit target industry
                  </span>
                  <span class="text-h5 font-weight-bold mx-n1" v-else>
                    Add target industry
                  </span>
                </v-col>
                <v-col cols="10" v-else-if="type==types.targetDepartment">
                  <span class="text-h5 font-weight-bold mx-n1" v-if="index">
                    Edit target department
                  </span>
                  <span class="text-h5 font-weight-bold mx-n1" v-else>
                    Add target department
                  </span>
                </v-col>
                <v-col cols="1">
                  <v-icon color="red" @click="onClose" large>
                    cancel
                  </v-icon></v-col
                >
                <v-col cols="1">
                  <v-icon large color="blue" @click="updateToolsAndPlatfrom" v-if="(index||index==0)">
                    done
                  </v-icon>
                  <v-icon large color="green" @click="createToolsAndPlatFrom" v-else>
                    done
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <div class="text-h6 font-weight-bold mx-4" v-if="type==types.toolsAndPlatfrom">
                  Tools/platfrom/methodology name<span class="red--text"
                    >*</span
                  >
                </div>
                <div class="text-h6 font-weight-bold mx-4" v-else-if="type==types.targetIndustry">
                  Target Industry<span class="red--text"
                    >*</span
                  >
                </div>
                <div class="text-h6 font-weight-bold mx-4" v-else-if="type==types.targetDepartment">
                  Target Department<span class="red--text"
                    >*</span
                  >
                </div>
              </v-row>
               <v-row>
               <small class="text-h7 font-weight-bold grey--text mx-4">After typing press enter or tab to save those values which are not in dropdown .</small>
            </v-row>
              <v-row class="mx-1" v-if="type==types.targetIndustry">
                <v-combobox 
                  v-model="trainerToolsAndPlatfrom.toolsOrIndustryName"
                  placeholder="Press tap to save..."
                  outlined
                  label=""
                  :items="industry"
                  required
                ></v-combobox>
              </v-row>
              <v-row class="mx-1" v-else-if="type==types.targetDepartment">
                <v-combobox 
                  v-model="trainerToolsAndPlatfrom.toolsOrIndustryName"
                  placeholder="Press tap to save..."
                  outlined
                  label=""
                  :items="departments"
                  required
                ></v-combobox>
              </v-row>
              <v-row class="mx-1" v-else-if="type==types.toolsAndPlatfrom">
                <v-combobox 
                  v-model="trainerToolsAndPlatfrom.toolsOrIndustryName"
                  placeholder="Press tap to save..."
                  outlined
                  label=""
                  :items="methoodology"
                  required
                ></v-combobox>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import {Industry,Departments,methodology} from '../data/FormData'
import { mapState } from "vuex";
export default {
  name: "toolsPlatfromDailoge",
  data() {
    return {
      valid: false,
      dialog: false,
      industry:Industry,
      departments:Departments,
      methoodology:methodology,
      types:{
        toolsAndPlatfrom:"TOOLSANDPLATFORM",
        targetIndustry:"TARGETINDUSTRY",
        targetDepartment:"TARGETDEPARTMENT"
      },
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerToolsAndPlatfrom: "trainerToolsAndPlatfrom"
    })
  },
  props: ["type","index"],
  created(){
    console.log('index' ,this.index);
    //  if(this.type =="TARGETINDUSTRY"){
    //       this.items =this.industry
    //     }else if(this.type =="TARGETDEPARTMENT"){
    //           this.items =this.departements
    //     }
  },
  methods: {
    getToolsPlatfrom(){
  
       
    },
    cb(){
      this.dialog=false
    },
    createToolsAndPlatFrom() {
      console.log('type ---' , this.type);
        this.trainerToolsAndPlatfrom.trainerId=this.profile.trainerId
        this.trainerToolsAndPlatfrom.type=this.type
        console.log('trainer tools andd paltfrom' , this.trainerToolsAndPlatfrom);
        this.$store.dispatch('trainerProfile/createTrainerToolsAndPlatfrom',{trainerTools:this.trainerToolsAndPlatfrom ,type:this.type ,cb:this.cb})
    },
    getToolsToEdit(){
    this.$store.dispatch('trainerProfile/getToolsAndPlatfrom',{type:this.type,index:this.index})
    },
    updateToolsAndPlatfrom(){
      this.$store.dispatch('trainerProfile/updateToolsAndPlatfrom',{trainerTools:this.trainerToolsAndPlatfrom ,type:this.type ,index:this.index,cb:this.cb})
    },
    onClose() {
      this.dialog = false;
    }
  }
};
</script>

<style></style>
