<template>
  <v-container fluid>
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-on="on" @click="getAllTargetAudience" v-if="edit">
          edit
        </v-icon>
        <v-icon color="primary" v-on="on" @click="getAllTargetAudience" v-else>
          add
        </v-icon>
      </template>
      <v-card outlined max-width="800px">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="10">
              <v-row>
                <span class="text-h5 font-weight-bold mx-2">
                  Target audience category
                </span>
              </v-row>
              <v-row no-gutters>
                <span class="small--text grey--text font-weight-light mx-1">
                  Click on item to select one , you can only select one
                </span>
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-icon color="red" @click="onClose" large>
                cancel
              </v-icon></v-col
            >
            <v-col cols="1">
              <v-icon large color="green" @click="createTragetAudience" v-if="!edit">
                done
              </v-icon>
              <v-icon large color="blue" @click="updateTargetAudince" v-else-if="edit">
                done
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-row v-if="targetAudeinces.length > 0" justify="space-around">
          <v-list
            v-for="(targetAudienceEntity, index) in targetAudeinces"
            :key="index"
          >
            <!-- <SelectTargetCard
              :targetAudienceEntity="targetAudienceEntity"
              :type="type"
            /> -->
            <v-card
              color="rgb(247,249,250)"
              width="280px"
              outlined
              @click="selectSkillCard(targetAudienceEntity)"
            >
              <v-card-text class="mt-n7">
                <v-row>
                  <v-col cols="12">
                    <div class="text-h6 font-weight-bold mx-1">
                      {{ targetAudienceEntity.targetAudienceName }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
// import SelectTargetCard from "./targetAudienceSelectCard.vue";
export default {
  name: "targetAudinceDailoge",
  components: {
    //SelectTargetCard
  },
  data() {
    return {
      valid: false,
      dialog: false,
      targetAudeinces:[],
      selectedTargetAudeince: null
    };
  },
  props: ["type","edit"],
  created() {
    console.log("type dailog dailoge -------", this.type , this.edit);
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerSkillServicesDto: "trainerSkillServicesDto"
    })
  },
  methods: {
    cb() {
      this.dialog = false;
    },
    createTragetAudience() {
      this.$store.dispatch("trainerProfile/createTrainerTargetAudince", {
        targetAudience: this.selectedTargetAudeince,
        type: this.type,
        cb:this.cb
      });
    },
    onClose() {
      this.dialog = false;
    },
    async getAllTargetAudience() {
      let response = await this.$store.dispatch(
        "trainerProfile/getAllTargetAudience"
      );
      this.targetAudeinces = response;
    },
    selectSkillCard(targetAudienceEntity) {
      this.selectedTargetAudeince = targetAudienceEntity;
    },
    updateTargetAudince(){
      this.$store.dispatch("trainerProfile/updateTargetAudience", {
        targetAudience: this.selectedTargetAudeince,
        type: this.type,
        cb:this.cb
      });
    }
  }
};
</script>

<style></style>
