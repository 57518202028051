<template>
 <v-container fluid>
    <v-card :color="colorOfCard" :width="!view?editModeWidth:null" outlined  dark max-width="250px" min-height="100px">
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="8">
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col cols="2">
            <v-icon color="red" @click="deleteSkill" v-if="!view && !skillEntitiy.trainerVerifiedSkill">
              cancel
            </v-icon>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text class="mt-n7">
        <v-row  justify="center">
          <v-col cols="12" align-self="auto">
            <div class="text-h6 font-weight-bold mx-5 my-2 text--primary">
              {{skillEntitiy.skillName}}
            </div>
          </v-col>
        </v-row>
        <!-- <v-row class="mt-1" v-if="skillEntitiy.skillSynonyms.length > 0">
          <div class="text-h6 font-weight-bold text--black">
          </div>
        </v-row> -->
        <!-- <v-row>
          <v-col>
             <div
            class="text-h7"
            v-for="(skill, index) in skillEntitiy.skillSynonyms"
            :key="index"
          >
           <v-chip color="green" class="mx-1 my-1"
      outlined>
                {{ skill }} 
              </v-chip>
          
          </div>
          </v-col>
         
        </v-row> -->
      </v-card-text>
    </v-card>
  </v-container> 
</template>

<script>
export default {
    name:"SkillCard",
    props:["skillEntitiy","view"],
    data() {
      return {
        editModeWidth:"240px",
        viewColor:"rgb(247,249,250)",
        editColor:"#EBEEF0",
        colorOfCard:"#EBEEF0"
      }
    },
    created() {
       if(this.view){
         if(this.skillEntitiy.dueDeligenceVerifiedSkill && this.skillEntitiy.trainerVerifiedSkill){
           this.colorOfCard ="#B9F6CA"
         }else if(this.skillEntitiy.dueDeligenceVerifiedSkill || this.skillEntitiy.trainerVerifiedSkill){
            this.colorOfCard ="#E0F7FA"
         }else{
           this.colorOfCard = this.viewColor
         }
        }else {
         this.colorOfCard= this.editColor
        }
    },
    methods:{
      deleteSkill(){
        this.$emit('delete-skill',this.skillEntitiy)
      }
    }
}
</script>

<style>

</style>