<template>
  <v-container fluid>
    <v-card flat>
      <v-row>
        <v-col cols="11">
          <div class="text-h6 font-weight-bold mx-1 primary--text">
            Target audience <ToolTip :tooltipText="skillAndServicesHelpText.targetAudincePrimary"/>
          </div>
        </v-col>
        <v-col cols="1" class="ml-n5 my-n3">
          <TargetAudinceDailoge :type="type" :edit="edit"/>
        </v-col>
      </v-row>
      <v-row class="mx-2">
          <v-card flat>
          <v-row>
            <v-col>
               <!-- <div class="text-h6 font-weight-bold mx-1 primary--text">
               Targete audience name
              </div>   -->
            </v-col>
          </v-row>
          <v-row > 
            <v-col class="mr-5 my-n3">
              <SelectTargetCard v-if="type==types.primary" :targetAudienceEntity="trainerSkillServicesDto.primarySkillsTargetAudience" :view="view"/>
              <SelectTargetCard v-else-if="type==types.secondary" :targetAudienceEntity="trainerSkillServicesDto.secondarySkillsTargetAudience" :view="view"/>
              <SelectTargetCard v-else-if="type==types.aspirational" :targetAudienceEntity="trainerSkillServicesDto.aspirationalSkillsTargetAudience" :view="view"/>
            </v-col>
            <!-- <v-col>
              <v-checkbox disabled> </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox disabled> </v-checkbox>
            </v-col> -->
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import SelectTargetCard from './targetAudienceSelectCard.vue'
import TargetAudinceDailoge from './targetAudinceDailoge.vue'
import ToolTip from './Tooltip/tooltip.vue'
import {SkillAndServices} from '../data/HelpTexts'
export default {
  name: "TargetAudienceCard",
  props:["type","edit"],
  data() {
    return {
      skillAndServicesHelpText:SkillAndServices,
      view:true,
      types:{
      primary:"PRIMARY_SKILLS_TARGET_AUDIENCE",
      secondary:"SECONDARY_SKILLS_TARGET_AUDIENCE",
      aspirational:"ASPIRATIONAL_SKILLS_TARGET_AUDIENCE"
    },
    }
  },
  components:{
     TargetAudinceDailoge ,
     SelectTargetCard,
     ToolTip
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerSkillServicesDto: "trainerSkillServicesDto",
      trainerTargetAudience:"trainerTargetAudience"
    })
  },
};
</script>

<style></style>
