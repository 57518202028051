<template>
<v-container fluid>
<v-card v-if="trainerSkillServicesDto.trainerServiceDelivery" flat>
  <v-row>
    <v-col cols="11">
      <div class="text-h6 font-weight-bold mx-3 my-3">
        Service delivery expereince 
      </div>
    </v-col>
    <v-col>
      <SkillAndServicesDailoge />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Total work expereince 
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold text--primary">
      
          {{trainerSkillServicesDto.trainerServiceDelivery.totalWorkExYears}} Years
          {{trainerSkillServicesDto.trainerServiceDelivery.totalWorkExMonths}} Months
      </div>
    </v-col>
  </v-row>
  <!-- <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Total training expereince 
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.totalTrainingExYears}} Years
          {{trainerSkillServicesDto.trainerServiceDelivery.totalTrainingExMonths}} Months
      </div>
    </v-col>
  </v-row> -->
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Total training expereince 
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.totalTrainingExYears}} Years
          {{trainerSkillServicesDto.trainerServiceDelivery.totalTrainingExMonths}} Months
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Total training sessions delivered 
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.trainingSessionDelivered}} 
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Average percentage of time dedicated to consulting
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.timeDedicatedConsulting}} 
      </div>
    </v-col>
  </v-row>
  <!-- <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Average trainings delivered to companies in a month
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.averageTrainingDeliveredMonthly}} 
      </div>
    </v-col>
  </v-row> -->
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Average total training delivered to college in a month
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.averageTrainingDeliveredMonthlyCollege}} 
      </div>
    </v-col>
  </v-row>
   <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Total Number of clients training delivered so far
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.totalClientsTrainingDelivered}} 
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Percentage of businnes directly received from end customber
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold  text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.businnessFromEndCustomber}} 
      </div>
    </v-col>
  </v-row>
   <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Key clients
      </div>
    </v-col>
    <v-col cols="6">
      <v-row class="text-h7 font-weight-bold text--primary">
        <v-list  v-for="(keyClient,index) in trainerSkillServicesDto.trainerServiceDelivery.keyClients" :key="index">
            {{keyClient}} ,  
        </v-list>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Key clients visible to ot custombers
      </div>
    </v-col>
    <v-col cols="6">
      <v-row  class="text-h7 font-weight-bold text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.keyClientsVisibility?"YES":"NO"}} 
      </v-row >
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Repeated clients
      </div>
    </v-col>
    <v-col cols="6">
      <v-row  class="text-h7 font-weight-bold text--primary">
        <v-list v-for="(repeactClient,index) in trainerSkillServicesDto.trainerServiceDelivery.repeatClients" :key="index">
            {{repeactClient }} , 
        </v-list>
          
      </v-row >
    </v-col>
  </v-row>
   <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Global clients
      </div>
    </v-col>
    <v-col cols="6">
      <v-row class="text-h7 font-weight-bold text--primary">
        <v-list v-for="(globalClient,index) in trainerSkillServicesDto.trainerServiceDelivery.globalClients" :key="index">
          {{globalClient}}  , 
        </v-list>
          
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold mx-3 my-3 primary--text">
        Global clients visible to ot custombers
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-h7 font-weight-bold text--primary">
          {{trainerSkillServicesDto.trainerServiceDelivery.globalClientVisibility?"YES":"NO" }} 
      </div>
    </v-col>
  </v-row>
  </v-card>
</v-container>
  
</template>
<script>
import { mapState } from "vuex";
import SkillAndServicesDailoge from './SkillAndServicesDailog/skillAndServices.vue'
export default {
    name:"ServiceDeliveryExpreinceCard",
    components:{
    SkillAndServicesDailoge 
    },
    data() {
      return {
        
      }
    },
    methods: {
      
    },
    created(){
      console.log('years',this.trainerSkillServicesDto.trainerServiceDelivery);
    },
    computed:{
    ...mapState('trainerProfile',{
      loading: "loading",
      profile: "profile",
      trainerSkillServicesDto:'trainerSkillServicesDto'
    })
    }
}
</script>

<style>

</style>