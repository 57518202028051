<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <span
          class="test_cursor text-h6 font-weight-bold primary--text"
          v-on="on"
        >
          Skills not in ot skill graph inventory ?
        </span>
      </template>
      <v-card outlined>
        <v-form v-model="valid" ref="createEditSkillForm">
          <v-card-title>
            <v-row>
              <v-col cols="10">
                <span class="text-h5 font-weight-bold mx-n1" v-if="type==types.primary">
                  Primary Skill
                </span>
                <span class="text-h5 font-weight-bold mx-n1" v-else-if="type==types.secondary">
                  Secondary Skill
                </span>
                <span class="text-h5 font-weight-bold mx-n1" v-else-if="type==types.aspirational">
                  Aspirational Skill
                </span>
              </v-col>
              <v-col cols="1">
                <v-icon color="red" @click="onClose" large>
                  cancel
                </v-icon></v-col
              >
              <v-col cols="1">
                <v-icon color="green" @click="createNonInventorySkills" large>
                  done
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <div class="text-h6 font-weight-bold mx-4">
                Primary skill name<span class="red--text">*</span>
              </div>
            </v-row>
            <v-row class="mx-1">
              <v-text-field
                v-model="name"
                placeholder="Type here . . . . "
                outlined
                label="Add Skill Name"
                solo
                required
              ></v-text-field>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SkillNotInOtInVentory",
  data() {
    return {
      valid: false,
      dialog: false,
      name:'',
      types:{
        primary:"PRIMARY_SKILLS_NON_INVENTORY",
        secondary:"SECONDARY_SKILLS_NON_INVENTORY",
        aspirational:"ASPIRATIONAL_SKILLS_NON_INVENTORY"
      }
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerSkills: "trainerSkills"
    })
  },
  props: ["type"],
  methods: {
    cb() {
      (this.dialog = false), this.$refs.createEditSkillForm.reset();
      this.name='';
      this.$store.dispatch('trainerProfile/resetTrainerSkills')
    },
    clicked() {
      console.log("clicked");
    },
    createNonInventorySkills() {
      console.log("not in inventory", this.trainerSkills);
      this.trainerSkills.skillName=this.name
      this.trainerSkills.skillType = this.type;
      console.log('trainer skills ---->' , this.trainerSkills);
      this.trainerSkills.trainerId = this.profile.trainerId;
      this.$emit("create-non-inventory", {
        trainerSkill: this.trainerSkills,
        cb: this.cb
      });
      // this.$store.dispatch('trainerProfile/createTrainerSkills',{skill:this.trainerSkills,type:this.type ,cb:this.cb})
    },
    onClose() {
      this.dialog = false;
    }
  }
};
</script>

<style>
.test_cursor {
  cursor: pointer;
}
</style>
