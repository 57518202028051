<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1400px">
      <template v-slot:activator="{ on }">
        <v-icon
          color="primary"
          dark
          v-on="on"
          v-if="!trainerSkillServicesDto.trainerServiceDelivery"
          >add</v-icon
        >
        <v-icon
          color="primary"
          dark
          v-on="on"
          v-else
          @click="getSkillAndService"
          >border_color</v-icon
        >
      </template>
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="10">
              <span class="text-h5 font-weight-bold mx-3" v-if="!trainerSkillServicesDto.trainerServiceDelivery"
                >Add service delivery expereince</span>
                <span class="text-h5 font-weight-bold mx-3"
                  v-else
                >Edit service delivery expereince</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon
                color="red"
                @click="closeSkillAndServices"
                dark
                large
                dense
              >
                cancel
              </v-icon>
            </v-col>
            <v-col cols="1">
              <v-icon
                color="green"
                v-if="!trainerSkillServicesDto.trainerServiceDelivery"
                @click="createSkillAndServices"
                dark
                large
                dense
              >
                done
              </v-icon>
              <v-icon
                color="blue"
                v-else
                @click="udapteTaienrSkillService"
                dark
                large
                dense
              >
                done
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="createSkillForm">
            <v-row >
              <v-col cols="6">
                <div class="text-h6 font-weight-bold text--primary">
                  Total work experince years <span class="red--text"></span>
                </div>
                <v-text-field
                  outlined
                  type="number"
                  v-model="trainerSkillSerivices.totalWorkExYears"
                  label=""
                  placeholder="Type here ...."
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <div class="text-h6 font-weight-bold text--primary">
                  Total work experince in months <span class="red--text"></span>
                </div>
                <v-text-field
                  outlined
                  type="number"
                  placeholder="Type here ...."
                  v-model="trainerSkillSerivices.totalWorkExMonths"
                  label=""
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Total fulltime training experince in years
                  <span class="red--text"></span>
                </div>
                <v-text-field
                  outlined
                   type="number"
                  placeholder="Type here ...."
                  v-model="trainerSkillSerivices.totalTrainingExYears"
                  label=""
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Total fulltime training experince in months
                  <span class="red--text"></span>
                </div>
                <v-text-field
                  outlined
                   type="number"
                  placeholder="Type here ...."
                  v-model="trainerSkillSerivices.totalTrainingExMonths"
                  label=""
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="text-h6 font-weight-bold text--primary">
                  Total training session delivered
                  <span class="red--text"></span>
                </div>
                <v-select
                  outlined
                  v-model="trainerSkillSerivices.trainingSessionDelivered"
                  :items="sessionDelivered"
                  placeholder="Select from below .."
                  label=""
                  required
                ></v-select>
              </v-col>
              <!-- <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Average training delivered to companies in a month
                  <span class="red--text"></span>
                </div>
                <v-select
                  outlined
                  v-model="
                    trainerSkillSerivices.averageTrainingDeliveredMonthly
                  "
                  label=""
                  required
                   :items="sessionDelivered"
                  placeholder="Select from below .."
                ></v-select>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Average percentage of time dedicated to consulting
                  <span class="red--text"></span>
                </div>
                <v-text-field
                  type="number"
                  outlined
                  placeholder="Type numbers  here only ...."
                  suffix="%"
                  v-model="trainerSkillSerivices.timeDedicatedConsulting"
                  label=""
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Average total training delivered to college in a month
                  <span class="red--text"></span>
                </div>
                <v-text-field
                  v-model="trainerSkillSerivices.averageTrainingDeliveredMonthlyCollege"
                  outlined
                  label=""
                  placeholder="Type numbers  here only ...."
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Total number of clients you have delivered training so far
                  <span class="red--text"></span>
              </div>
                <v-select
                  outlined
                  v-model="trainerSkillSerivices.totalClientsTrainingDelivered"
                  label=""
                   placeholder="Select from below"
                  required
                  :items="totalNumberCleintsTraining"
                ></v-select>
              </v-col>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Percentage of business directly received from end customer
                  <span class="red--text"></span>
              </div>
                <v-text-field
                outlined
                suffix="%"
                v-model="trainerSkillSerivices.businnessFromEndCustomber"
                label=""
                placeholder="Type numbers  here only ...."
                required
                type="number"
              ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Key clients
                  <span class="red--text"></span>
                </div>
                <small class="text-h8 font-weight-bold grey--text">After typing press enter or tab to input more values</small>
                <v-combobox
                outlined
                  class="elevation-0"
                  label=""
                  chips
                  multiple
                  :items="clientList"
                  clearable
                  v-model="trainerSkillSerivices.keyClients"
                  placeholder="After typing press enter to input more values"
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      :input-value="select"
                      close
                      @click:close="removeKeyClients(data.item)"
                      text-color="black"
                      small
                    >
                      <strong>{{ data.item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Repeated clients
                  <span class="red--text"></span>
              </div>
              <small class="text-h8 font-weight-bold grey--text">After typing press enter or tab to input more values</small>
                <v-combobox
                  class="elevation-0"
                  outlined
                  label=""
                  chips
                  multiple
                  :items="clientList"
                  clearable
                  v-model="trainerSkillSerivices.repeatClients"
                  placeholder="After typing press enter to input more values"
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      :input-value="select"
                      close
                      @click:close="removeRepeatedClients(data.item)"
                      text-color="black"
                      small
                    >
                      <strong>{{ data.item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-h6 font-weight-bold text--primary">
                  Client outside india
                  <span class="red--text"></span>
              </div>
                <small class="text-h8 font-weight-bold grey--text">After typing press enter or tab to input more values</small>
                <v-combobox
                outlined
                  class="elevation-0"
                  label=""
                  chips
                  multiple
                  clearable
                  :items="clientList"
                  v-model="trainerSkillSerivices.globalClients"
                  placeholder="After typing press enter to input more values"
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      :input-value="select"
                      close
                      @click:close="removeGlobalClients(data.item)"
                      text-color="black"
                      small
                    >
                      <strong>{{ data.item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
              class="text-h6 font-weight-bold text--primary"
                v-model="trainerSkillSerivices.keyClientsVisibility"
                label="Make my key clients visible to Ot clients/custombers"
              />
              </v-col>
              <v-col>
                 <v-checkbox
              class="text-h6 font-weight-bold text--primary"
                v-model="trainerSkillSerivices.globalClientVisibility"
                label="Make my Internations clients visible to Ot clients/custombers"
              />
              </v-col>
            </v-row>
            <v-row>
             
            </v-row>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {SessionDelivered,Departments ,totalNumberCleintsTraining ,CleintList} from '../../data/FormData'
import { mapState } from "vuex";
export default {
  data: () => ({
    clientList:CleintList,
    sessionDelivered:SessionDelivered,
    departments:Departments,
    totalNumberCleintsTraining:totalNumberCleintsTraining ,
    dialog: false,
    files: [],
    select: ["add-tags-with", "enter", "tab", "paste"],
    rules: [
      (value) => !!value || "Required.",
      (value) => (value && value.length >= 3) || "Min 3 characters"
    ]
  }),
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      trainerSkillSerivices: "trainerSkillSerivices",
      trainerSkillServicesDto:"trainerSkillServicesDto"
    })
  },
  methods: {
    cb() {
      this.dialog = false, 
      this.$refs.createSkillForm.reset();
      //createSkillForm
    },
    createSkillAndServices() {
      
      let skillAndService = this.trainerSkillSerivices;
      skillAndService.trainerId = this.profile.trainerId;
      this.$store.dispatch("trainerProfile/createSkillsAndServices", {
        trainerSkills: skillAndService,
        cb: this.cb
      });
    },
    udapteTaienrSkillService(){
         this.$store.dispatch("trainerProfile/updateSkillsAndServices", {
        trainerSkills:this.trainerSkillSerivices,
        cb: this.cb
      });
    },
    removeKeyClients(item) {
      console.log("item", item);
      this.trainerSkillSerivices.keyClients.splice(
        this.trainerSkillSerivices.keyClients.indexOf(item),
        1
      );
      this.trainerSkillSerivices.keyClients = [
        ...this.trainerSkillSerivices.keyClients
      ];
    },
    removeRepeatedClients(item) {
      console.log("repeat clients ", item);
      this.trainerSkillSerivices.repeatClients.splice(
        this.trainerSkillSerivices.repeatClients.indexOf(item),
        1
      );
      this.trainerSkillSerivices.repeatClients = [
        ...this.trainerSkillSerivices.repeatClients
      ];
    },
    removeGlobalClients(item) {
      console.log(item);
      this.trainerSkillSerivices.globalClients.splice(
        this.trainerSkillSerivices.globalClients.indexOf(item),
        1
      );
      this.trainerSkillSerivices.globalClients = [
        ...this.trainerSkillSerivices.globalClients
      ];
    },
    getSkillAndService() {
      this.$store.dispatch("trainerProfile/getSkillsAndServiceDelivery");
    },
    closeSkillAndServices() {
      this.dialog = false;
      console.log("somethit");
    }
  }
};
</script>
