<template>
  <v-container fluid>
    <v-card flat>
      <v-row>
        <v-col cols="9">
          <div class="text-h6 font-weight-bold mx-1" v-if="type==types.primarySkill">
          Rough and  primary skills
          </div>
          <div class="text-h6 font-weight-bold mx-1" v-else-if="type==types.secondarySkills">
            Secondary skills 
          </div>
          <div class="text-h6 font-weight-bold mx-1" v-else-if="type==types.aspirationationalSkills">
            Aspirational skills
          </div>
        </v-col>
        <v-col cols="2">
          <VerifySkillDailoge :type="type"/>
        </v-col>
        <v-col cols="1">
            <PrimarySkillEdit :type="type"/>
        </v-col>
      </v-row>
      <v-row v-if="type==types.primarySkill && trainerSkillServicesDto.primarySkills.length>0">
       <v-col>
         <div v-if="type==types.primarySkill && trainerSkillServicesDto.primarySkills.length>0">
        <v-list v-for="(skillEntity,index) in trainerSkillServicesDto.primarySkills" :key="index">
          <VerificationSkillCard :skillEntitiy="skillEntity"/>
        </v-list>
      </div> 
       </v-col>
      </v-row>
      <v-row v-else-if="type==types.secondarySkills && trainerSkillServicesDto.secondarySkills.length>0">
       <v-col>
         <div>
        <v-list v-for="(skillEntity,index) in trainerSkillServicesDto.secondarySkills" :key="index">
          <VerificationSkillCard :skillEntitiy="skillEntity"/>
        </v-list>
      </div> 
       </v-col>
      </v-row>
      <v-row v-else-if="type==types.aspirationationalSkills && trainerSkillServicesDto.aspirationalSkills.length>0">
     <v-col>
       <div >
        <v-list v-for="(skillEntity,index) in trainerSkillServicesDto.aspirationalSkills" :key="index">
          <VerificationSkillCard :skillEntitiy="skillEntity"/>
        </v-list>
      </div>
     </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import VerificationSkillCard from './VerificataionSkillCard.vue'
import PrimarySkillEdit from './primarySkilldailog.vue'
import VerifySkillDailoge from './verifySkillDailoge.vue'
export default {
  name: "PrimarySkillCard",
  components: {
  //  SKillCardView,
    VerificationSkillCard,
    PrimarySkillEdit,
    VerifySkillDailoge
  },
  data() {
    return {
       types:{
        primarySkill:"PRIMARY_SKILLS",
        secondarySkills:"SECONDARY_SKILLS",
        aspirationationalSkills:"ASPIRATIONAL_SKILLS"
      }
    }
  },
  props: ["type"],
  computed: {
    ...mapState("trainerProfile", {
      profile:"profile",
      loading:"loading",
      editMode:'editMode',
      trainerSkillServicesDto:"trainerSkillServicesDto"
    })
  },
};
</script>

<style></style>
