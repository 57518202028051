<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <template v-slot:activator="{ on }">
        <v-icon
          color="primary"
          dark
          @click="getSkills"
          v-on="on"
          v-if="type == types.primarySkill"
          >{{
            trainerSkillServicesDto.primarySkills.length == 0
              ? "add"
              : "border_color"
          }}</v-icon
        >
        <v-icon
          color="primary"
          dark
          v-on="on"
          @click="getSkills"
          v-else-if="type == types.secondarySkills"
          >{{
            trainerSkillServicesDto.secondarySkills.length == 0
              ? "add"
              : "border_color"
          }}</v-icon
        >
        <v-icon
          color="primary"
          dark
          v-on="on"
          @click="getSkills"
          v-else-if="type == types.aspirationationalSkills"
          >{{
            trainerSkillServicesDto.aspirationalSkills.length == 0
              ? "add"
              : "border_color"
          }}
        </v-icon>
      </template>
      <SkillAlertDailoge />
      <v-card flat>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="10">
              <span
                class="text-h6 font-weight-bold text--primary mx-n3"
                v-if="type == types.primarySkill"
                >{{
                  editMode.skillAndServicesEditMode ? "Edit" : "Add"
                }}
                primary skills</span
              >
              <span
                class="text-h5 font-weight-bold mx-3"
                v-else-if="type == types.secondarySkills"
                >{{
                  editMode.skillAndServicesEditMode ? "Edit" : "Add"
                }}
                secondary skills</span
              >
              <span
                class="text-h5 font-weight-bold mx-3"
                v-else-if="type == types.aspirationationalSkills"
                >{{
                  editMode.skillAndServicesEditMode ? "Edit" : "Add"
                }}
                aspirational skills</span
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1">
              <v-icon @click="closeForm" color="red" large>
                cancel
              </v-icon>
            </v-col>
            <v-col cols="1">
              <v-icon color="green" @click="createSkills" large>
                done
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-row no-gutters>
          <span
            class="text-h5 black--text font-weight-bold mx-3 my-3"
            v-if="type == types.primarySkill && trainerSkillServicesDto.primarySkills.length > 0"
          >
            Selected primary skills</span
          >
          <span
            class="text-h5 black--text font-weight-bold mx-3 my-3"
            v-else-if="type == types.secondarySkills"
          >
            Selected secondary skills</span
          >
          <span
            class="text-h5 black--text font-weight-bold mx-3 my-3"
            v-else-if="type == types.aspirationationalSkills"
          >
            Selected aspirational skills</span
          >
        </v-row>
        <v-row
          v-if="selectedPrimarySkills.length > 0"
          no-gutters
        >
          <v-list
            v-for="(primarySkill,
            index) in selectedPrimarySkills"
            :key="index"
          >
            <SkillCardView :skillEntitiy="primarySkill"  @delete-skill="deleteSkill"/>
          </v-list>
        </v-row>
        <!-- <v-row
          v-else-if="
            trainerSkillServicesDto.secondarySkills.length > 0 &&
              type == types.secondarySkills
          "
        >
          <v-list
            v-for="(secondarySkills,
            index) in selectedPrimarySkills"
            :key="index"
          >
            <SkillCardView :skillEntitiy="secondarySkills" />
          </v-list>
        </v-row> -->
        <!-- <v-row
          v-else-if="
            trainerSkillServicesDto.aspirationalSkills.length > 0 &&
              type == types.aspirationationalSkills
          "
        >
          <v-list
            v-for="(aspiraitionalSkill,
            index) in selectedPrimarySkills"
            :key="index"
          >
            <SkillCardView :skillEntitiy="aspiraitionalSkill"  @delete-skill="deleteSkill"/>
          </v-list>
        </v-row> -->
        <v-row justify="center">
          <v-col cols="8">
            <v-autocomplete
              rounded
              outlined
              ref="el"
              v-model="model"
              :loading="isLoading"
              :search-input.sync="search"
              item-text="Description"
              item-value="API"
              label=""
              :items="items"
              hide-no-data
              hide-details
              hide-selected
              :return-object="returnValue"
              @change="changeCalled"
              placeholder="Search skills"
              prepend-inner-icon="search"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    <strong>Search for your skills</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content @click="seletedSkills(item)">
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters class="mx-5 my-9">
          <SkillNotInINventory :type="skillNonInventoryType"  @create-non-inventory="addNonInventory"/>
        </v-row>
      </v-card>
      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="red"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          outlined
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-dialog>
  </v-container>
</template>
<script>
import SkillNotInINventory from "./skillNotInInnventory.vue";
import SkillAlertDailoge from './skillalertDailoge.vue'
import SkillCardView from "./skillCardView.vue";
import { mapState } from "vuex";
export default {
  name: "primarySkillDailoge",
  components: {
    SkillNotInINventory,
    SkillCardView,
    SkillAlertDailoge
  },
  data() {
    return {
      dialog: false,
      model: null,
      isLoading: false,
      snackbar: false,
      text: 'Skill is already part of rough skill or secondry skills or aspirational  skills',
      timeout: 10000,
      search: null,
      items: [],
      returnValue: false,
      searchResult: [],
      selectedPrimarySkills: [],
      selectedPrimarySkill: null,
      skillNonInventoryType: null,
      types: {
        primarySkill: "PRIMARY_SKILLS",
        secondarySkills: "SECONDARY_SKILLS",
        aspirationationalSkills: "ASPIRATIONAL_SKILLS"
      },
      column: true
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerSkillServicesDto: "trainerSkillServicesDto",
      editMode: "editMode"
    })
  },
  props: ["type"],
  created() {
    console.log("type-------------------- ", this.type, this.profile);
    if (this.type == "PRIMARY_SKILLS") {
      this.skillNonInventoryType = "PRIMARY_SKILLS_NON_INVENTORY";
    } else if (this.type == "SECONDARY_SKILLS") {
      this.skillNonInventoryType = "SECONDARY_SKILLS_NON_INVENTORY";
    } else if (this.type == "ASPIRATIONAL_SKILLS") {
      this.skillNonInventoryType = "ASPIRATIONAL_SKILLS_NON_INVENTORY";
    }
  },
  watch: {
    async search(val) {
      if (val && val.length >= 3) {
        this.isLoading = true;
        let responseFromSearch = await this.$store.dispatch(
          "trainerProfile/serachPrimarySkills",
          val
        );
        this.searchResult = responseFromSearch;
        this.items = [];
        for (let j = 0; j < this.searchResult.length; j++) {
          this.items.push(this.searchResult[j].skillPath);
        }

        this.isLoading = false;
      }
    }
  },
  methods: {
    addNonInventory({cb,trainerSkill}){
      console.log('trainer skills' ,trainerSkill,'cb' ,cb);
       if(!this.checkIfSkillAlreadyPresentOrNot(trainerSkill)){
          this.selectedPrimarySkills.push(trainerSkill);
        if(cb){
        cb()
      }
        }else {
          this.snackbar=true
              // show snack bar
        }
        
    },
    checkIfSkillAlreadyPresentOrNot(skill){
      let skillArray =[];
      let present=false;
      let primarySkills  = Object.assign([], this.trainerSkillServicesDto.primarySkills)
      let secondrySkills = Object.assign([],this.trainerSkillServicesDto.secondarySkills);
      let aspirationalSkills  =Object.assign([],this.trainerSkillServicesDto.aspirationalSkills); 
      skillArray.push(...primarySkills ,...secondrySkills ,...aspirationalSkills)
      if(!skill.skillIdFromSkillId){
        // then check by skill name
        for(let i=0;i<skillArray.length;i++){
          if(skillArray[i].skillName.toUpperCase()==skill.skillName.toUpperCase()){
            present =true;
            break;
          }
       }    
      }else {
        for(let i=0;i<skillArray.length;i++){
          if(skillArray[i].skillIdFromSkillId ==skill.skillIdFromSkillId){
            present =true;
            break;
          }
       }
      }
      return present;
    },
    seletedSkills(item) {
      //
      for (let j = 0; j < this.searchResult.length; j++) {
        if (this.searchResult[j].skillPath == item)
        this.selectedPrimarySkill = this.searchResult[j];
      } 
      this.search = null;
      // modify and push to array
      let primarySkill = {
        trainerId:this.profile.trainerId,
        skillType: this.type,
        skillName: this.selectedPrimarySkill.skillName,
        trainerVerifiedSkill: false,
        dueDeligenceVerifiedSkill: false,
        skillIdFromSkillId: this.selectedPrimarySkill.skillId
      };
      if(!this.checkIfSkillAlreadyPresentOrNot(primarySkill)){
              this.selectedPrimarySkills.push(primarySkill);
        }else{
          this.snackbar=true     
      }
    },
    changeCalled() {
      this.search = "";
    },
    closeForm() {
      this.dialog = false;
    },
    getSkills(){
      if(this.type== this.types.primarySkill){
        this.selectedPrimarySkills =this.trainerSkillServicesDto.primarySkills
      }else if(this.type== this.types.secondarySkills){
        this.selectedPrimarySkills =this.trainerSkillServicesDto.secondarySkills 
      }else if(this.type== this.types.aspirationationalSkills){
        this.selectedPrimarySkills =this.trainerSkillServicesDto.aspirationalSkills
      }
    },
    cb() {
      this.dialog = false;
      this.search=null
    },
    deleteSkill(skillEntity){
        console.log('skill entity to be deleted' ,skillEntity);
        // delelte from the current after showing the modal
        if(skillEntity.skillIdFromSkillId){
          // 
        this.selectedPrimarySkills =this.selectedPrimarySkills.filter(skill=>skill.skillIdFromSkillId !=skillEntity.skillIdFromSkillId)
        }else {
            this.selectedPrimarySkills =this.selectedPrimarySkills.filter(skill=>skill.skillName !=skillEntity.skillName)
        }
        },
   async  createSkills() {
  let response  = await this.$store.dispatch("trainerProfile/createTrainerSkills", {
        skill: this.selectedPrimarySkills,
        type: this.type,
        cb: this.cb()
      });
   console.log('resposne' ,response);   
    }
  }
};
</script>

<style></style>
