<template>
  <v-container fluid>
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on }">
        <span @click="getSkills"
          class="test_cursor text-h6 font-weight-bold primary--text"
          v-on="on"
        >
          Verify skills ?
        </span>
      </template>
      <v-card outlined>
        <v-form ref="skillVerifyForm">
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="10">
                <span class="text-h5 font-weight-bold mx-n1">
                  Verify skills
                </span>
              </v-col>
              <v-col cols="1">
                <v-icon color="red" @click="onClose" large>
                  cancel
                </v-icon>
              </v-col>
              <v-col cols="1">
                <v-icon color="green" @click="updateSkills" large>
                  done
                </v-icon>
              </v-col>
            </v-row>
            
          </v-card-title>
          <v-row no-gutters>
              <v-col cols="8">
                <span class="text-h7 font-weight-bold mx-1">
                  Skill verification by trainer happens at stage 1C . Skill due
                  deligence verification happens at stage 2A
                </span>
              </v-col>
            </v-row>
          <v-divider></v-divider>
          <v-card-text>
            <v-list v-for="(skillEntity,index) in skills" :key="index">
          <VerificationSkillCard :skillEntitiy="skillEntity" isVerifySkill=true />
        </v-list>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VerificationSkillCard from './VerificataionSkillCard.vue'
export default {
  name: "verifyskills",
  components:{
    VerificationSkillCard
  },
  data() {
    return {
      dialog: false,
      types:{
        primarySkill:"PRIMARY_SKILLS",
        secondarySkills:"SECONDARY_SKILLS",
        aspirationationalSkills:"ASPIRATIONAL_SKILLS"
      },
      skills:[],
      items:[
          {
              name:"Primary Skills",
              value:"PRIMARY_SKILLS",
          },
           {
              name:"Secondary Skills",
              value:"SECONDARY_SKILLS",
          },
           {
              name:"Aspirational Skills",
              value:"ASPIRATIONAL_SKILLS",
          }
      ]
    };
  },
  props:["type"],
  methods: {
    onClose() {
      console.log("on close called");
      this.dialog = false;
    },
    cb(){
        this.dialog = false;
    },
    updateSkills() {
        console.log('skills ' , this.skills);
      console.log("update skills called");
      this.$store.dispatch('trainerProfile/updateSkills',{
          cb:this.cb,
          skills:this.skills,
          type:this.type
      })
    },
    async getSkills(){
      let skillFromDto =await  this.$store.dispatch('trainerProfile/getSkillsToVerify' ,this.type)
        this.skills=skillFromDto;
        console.log('get skills based on type',skillFromDto);
    }
  }
};
</script>

<style>
.test_cursor {
  cursor: pointer;
}
</style>
