<template>
  <v-container fluid>
    <v-card flat>
      <v-row>
        <v-col cols="11">
          <div class="text-h6 font-weight-bold mx-1 primary--text" v-if="typeOf==types.toolsAndPlatfrom">
            Tools platfrom and methodology
          </div>
          <div class="text-h6 font-weight-bold mx-1 primary--text" v-else-if="typeOf==types.targetIndustry">
              Target Industry
          </div>
          <div class="text-h6 font-weight-bold mx-1 primary--text" v-else-if="typeOf==types.targetDepartment">
              Target Department
          </div>
        </v-col>
        <v-col v-if="typeOf==types.toolsAndPlatfrom">
          <ToolsPlatfromDailoge :type="types.toolsAndPlatfrom"/>
        </v-col>
        <v-col v-else-if="typeOf==types.targetIndustry">
          <ToolsPlatfromDailoge :type="types.targetIndustry"/>
        </v-col>
        <v-col v-else-if="typeOf==types.targetDepartment">
          <ToolsPlatfromDailoge :type="types.targetDepartment"/>
        </v-col>
      </v-row>
      <DeleteToolsDailoge :dialog="deleteAlertBoxSatus" @onCancel="onCancel" @onOk="confirmDelete"/>
      <v-row>
        <div v-if="trainerSkillServicesDto.toolsAndPlatFrom.length>0 && typeOf==types.toolsAndPlatfrom" class="mx-5">
         <v-list v-for="(toolsAndPlatfrom,index) in trainerSkillServicesDto.toolsAndPlatFrom" :key="index">
        <v-card outlined flat  width="300px">
          <v-row>
            <v-col cols="9">
               <div class="text-h6 font-weight-bold mx-2 my-n1 text--primary">
                {{toolsAndPlatfrom.toolsOrIndustryName}}
              </div>
            </v-col>
            <v-col cols="1" class="mr-1 my-n3">
                <ToolsPlatfromDailoge type="TOOLSANDPLATFORM" :index="index"/>            
            </v-col>
            <v-col cols="1" class="mr-1">
                <v-icon color="red" @click="deleteToolsAndPlarfrom(toolsAndPlatfrom)">
                  delete
                </v-icon>
            </v-col>
          </v-row>
        </v-card>
        </v-list> 
         </div>
        <div v-else-if="trainerSkillServicesDto.targetIndustryAndDepartment.length>0 && typeOf==types.targetIndustry" class="mx-5">
         <v-list v-for="(targetIndustry,index) in trainerSkillServicesDto.targetIndustryAndDepartment" :key="index">
        <v-card outlined flat  width="300px">
          <v-row>
            <v-col cols="9">
              <div class="text-h6 font-weight-bold mx-2 my-n1 text--primary">
                {{targetIndustry.toolsOrIndustryName}}
              </div>
            </v-col>
            <v-col cols="1" class="mr-1 my-n3">
                <ToolsPlatfromDailoge type="TARGETINDUSTRY" :index="index"/>            
            </v-col>
            <v-col cols="1" class="mr-1">
                <v-icon color="red" @click="deleteToolsAndPlarfrom(targetIndustry)">
                  delete
                </v-icon>
            </v-col>
          </v-row>
        </v-card>
        </v-list> 
         </div>
        <div v-else-if="trainerSkillServicesDto.targetDepartment.length>0 && typeOf==types.targetDepartment" class="mx-10">
         <v-list v-for="(targetDepartment,index) in trainerSkillServicesDto.targetDepartment" :key="index">
        <v-card outlined flat width="300px">
          <v-row>
            <v-col cols="9">
                <div class="text-h6 font-weight-bold mx-2 my-n1 text--primary">
                {{targetDepartment.toolsOrIndustryName}}
              </div>
            </v-col>
            <v-col class="mr-1 my-n3" cols="1">
                <ToolsPlatfromDailoge type="TARGETDEPARTMENT" :index="index"/>            
            </v-col>
            <v-col cols="1" class="mr-1">
                <v-icon color="red" @click="deleteToolsAndPlarfrom(targetDepartment)">
                  delete
                </v-icon>
            </v-col>
          </v-row>
        </v-card>
        </v-list> 
         </div>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import ToolsPlatfromDailoge from "./ToolsPlatfromDailoge.vue";
import { mapState } from "vuex";
import DeleteToolsDailoge from './delelteAlertboxTools.vue'
export default {
  name: "toolsPlatformCard",
  components: {
    ToolsPlatfromDailoge,
    DeleteToolsDailoge
  },
  data() {
    return {
      type:null,
      types:{
        toolsAndPlatfrom:"TOOLSANDPLATFORM",
        targetIndustry:"TARGETINDUSTRY",
        targetDepartment:"TARGETDEPARTMENT"
      },
    }
  },
  created() {
    this.type=this.typeOf
  },
  methods: {
    deleteToolsAndPlarfrom(toolsAndPlatfrom){
      console.log('delete tools called' ,toolsAndPlatfrom);
      this.$store.dispatch('trainerProfile/deleteToolsAndPlatfrom',{toolsAndPlatfrom:toolsAndPlatfrom , delteConfirmation:false ,type:this.type})
    },
    confirmDelete(){
          this.$store.dispatch('trainerProfile/deleteToolsAndPlatfrom',{toolsAndPlatfrom:this.afterOk.toolsAndPlatfrom ,type:this.afterOk.type, delteConfirmation:true })
    },
    onCancel(){
      this.$store.dispatch('trainerProfile/closeDeleteDailog')
    }
  },
  props:["typeOf"],
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerSkillServicesDto: "trainerSkillServicesDto",
      deleteAlertBoxSatus:'deleteAlertBoxSatus',
      afterOk:'afterOk'
    })
  },
};
</script>

<style></style>
